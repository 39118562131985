import { configureStore } from '@reduxjs/toolkit'
import { mainApi } from './services/mainApi'
import { salePageApi } from './services/salePageApi'

import allItemsSlice from './slices/allItems'
import allItemsCartSlice from './slices/allItemsCart'
import allItemsFavoritesSlice from './slices/allItemsFavorites'
import searchSlice from './slices/search'
import createOrderData from './slices/createOrderData'
import accountData from './slices/accountData'
import filterData from './slices/filterData'
import accModal from './slices/accModal'
import contextUI from './slices/contextUI'
import itemsCartSlice from './slices/itemsCart'
import teplateOrderSlice from './slices/templateOrder'

const combineReducers = {
  search: searchSlice,
  allItems: allItemsSlice,
  allItemsCart: allItemsCartSlice,
  allItemsFavorites: allItemsFavoritesSlice,
  createOrderData: createOrderData,
  accountData: accountData,
  filterData: filterData,
  accModal: accModal,
  contextUI: contextUI,
  itemsCart: itemsCartSlice,
  teplateOrder: teplateOrderSlice,
  [mainApi.reducerPath]: mainApi.reducer,
  [salePageApi.reducerPath]: salePageApi.reducer,
}

export const store = configureStore({
  reducer: combineReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(mainApi.middleware, salePageApi.middleware),
})
