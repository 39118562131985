import React, { useEffect, useRef, useState } from 'react'
import './SalePage.scss'
import { mainApi } from '../../store/services/mainApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Alert, Button, CircularProgress, IconButton, Snackbar, Typography } from '@mui/material'
import { useDispatch,useSelector } from 'react-redux'
import { setCartModal, setGramMode, setItemModal, setPriceIvaMode, setProMode } from '../../store/slices/contextUI'
import { Switch } from '@mui/material'
import axios from 'axios'
import CarNav from '../components/assets/images/Car-Nav.png'
import moment from 'moment/moment'
import NavAlphaList from '../components/NavAlphaList/NavAlphaList'
import AlpaSortIcon from '../components/assets/images/sortAlfabetIcon.svg'
import useGetConvertedCart from '../../hooks/useGetConvertedCart';
import useReplaceAccent from '../../hooks/useReplaceAccent';
import SalePageElement from './SalePageElement/SalePageElement';
import WarningModal from './../components/ModalWindows/WarningModal/WarningModal';
import ClassicModal from '../components/ModalWindows/ClassicModal/ClassicModal'
import ShareBtnIcon from '../components/assets/images/shareBtnIcon.svg'
import CloseIcon from '@mui/icons-material/Close';
import { salePageApi } from '../../store/services/salePageApi'


export default function SalePage() {
  const navigate = useNavigate()
  const tradeName = useParams().tradeName
  const platformParam = useParams().platform
  const dispatch = useDispatch()
  const { categoryName, categoryType, saleId } = useParams()
  const view = useSelector((state) => state.contextUI.proMode)

  //  66d96fc9c9b0239f42e97458
  const { data: salesData, isLoading: isLoadingSales } = salePageApi.useGetSalesOrderByIdQuery(
    saleId
  )
  const [updateOrder, { data: result, isSuccess}] = salePageApi.useUpdateSalesOrderByIdMutation()

  const userData = useSelector((state) => state.accountData.userAcc)
  const [id, setId] = React.useState('')
  const isLogin = useSelector((state) => state.contextUI.isLogin)
  useEffect(() => {
    if (tradeName !== '') {
        const tradeId = async () => { 
          const responseLogin = await axios.get(`https://tpomobi.shop/getBuisnessContactByTradeName?tradeName=${tradeName}`)
          setId(responseLogin.data?._id);
          localStorage.setItem('tradeNameCode', responseLogin.data?.tradeName)
          localStorage.setItem('tradeName', responseLogin.data?.name)}
        tradeId()
      }
  }, [userData, isLogin])

 
  const [eventStartTime, setEventStartTime] = useState(new Date(2024, 8, 7));
  // console.log('eventStartTime: '+eventStartTime);
  const [eventStatus, setEventStatus] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  // const [eventStatus, setEventStatus] = useState(true);
  
  
  // console.log('timeEnd: '+ new Date(salesData?.timeEnd*1000));

  useEffect(() => {
    if (!isLoadingSales) {
      const interval = setInterval(() => {  
        const date = new Date()
        setTimeLeft((salesData?.timeEnd - date.getTime()) / 1000);
        if (timeLeft < 0) {
        setEventStatus(false);
        clearInterval(interval);
        setTimeLeft(0)
        }
        
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isLoadingSales]);

  async function updateSaleOrder() {
    const now = new Date();
    console.log(now.getFullYear());
    const hour = 23;
    const minuteStart = 0;
    const minuteEnd = 59;

    now.setHours(0);
    now.setMinutes(minuteStart);
    now.setSeconds(0);
    now.setMilliseconds(0);
    const timeStartConst = now.getTime();
    
    now.setHours(hour);
    now.setMinutes(minuteEnd);
    const timeEndConst = now.getTime();
    await updateOrder({ id: saleId, body: {
      timeStart: timeStartConst,
      timeEnd: timeEndConst,
      title: 'test4',
    }}).then(res => console.log(res))
    console.log(result);
  }
  useEffect(() => {
    updateSaleOrder() 
  
  }, [])

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    if (time<=0) {
      return '0:00'
    }

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (timeLeft <= 0) {
      setEventStatus(false);
    } else {
      setEventStatus(true);
    }
  }, [timeLeft]);

  // console.log(useGetConvertedCart());
  const [rulesModal, setRulesModal] = useState(false);
  const [copyAlert, setCopyAlert] = useState(false);

  const handleClick = () => {
    setCopyAlert(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopyAlert(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className='categoriesMain'>
      {/* <CategoryNews/> */}
      <div className='categoriesMain__category'>
        <div className='categoriesMain__category__title'>{categoryName}</div>
      </div>
      {!isLoadingSales && salesData !== undefined &&
      <div className='Timer'>
        <span>{!eventStatus ?'Event not started' : 'Descuento por la Compra Colectiva'}</span>
        {formatTime(timeLeft.toFixed(0))}
      </div>}
      <Snackbar
        open={copyAlert}
        autoHideDuration={1000}
        onClose={handleClose}
        message='Link was copied'
        action={
          action
        }
        />
      <div 
        className='SalePage__shareBtn'
        onClick={() => {
          navigator.clipboard.writeText('https://tpoapp.com/sale')
          handleClick()
        }}
      >
        <img src={ShareBtnIcon} alt="" />
        <div className='SalePage__shareBtn__text'>
          Invita vecinos para ahorrar mas
        </div>
      </div>
      {/* {!eventStatus ? <></> : 
        <div className='ShadowPage__search'>
        Buscar en mi lista
          <div style={{display: 'flex', alignItems: 'center'}}>
            <input 
              type="text" 
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div
              className='AlpabetSortBtn'
              onClick={() => {window.scrollTo({top: 251, behavior: 'smooth'})}}
            >
              <img src={AlpaSortIcon} alt="" />
            </div>
            <div 
              className='ShadowPage__search__clearBtn'
              style={{display: searchText !== '' ? 'flex' : 'none'}}
              onClick={() => setSearchText('')}
            >
              X
            </div>
          </div>
        </div>} */}
      
      {eventStatus && salesData?.salesItems !== undefined && 
      <NavAlphaList list={salesData.salesItems} disabled={true}>
        {isLoadingSales
        ? <CircularProgress/>
        :  salesData.salesItems?.sort((a, b) => a.name.localeCompare(b.name))?.map((item) => (
          <div key={item.id} className="NavAlphaList__list-item" data-letter={item.name[0].toUpperCase()}>
            <SalePageElement item={item} key={item.id+'Element'}/>
          </div>
        ))}
      </NavAlphaList>}

      <div
        className='ThanksModal__backBtn'
        style={{margin: 'auto', marginTop: '20px', color: '#fff', cursor: 'pointer'}}
        onClick={() => {if (platformParam !== undefined) {navigate(`/newOrder/${platformParam}/${tradeName}`)} else {navigate(`/newOrder/${tradeName}`);} window.scrollTo(0, 0)}}
      >
        Ver furgoneta
        <img 
          src={CarNav}
          alt=""
          style={{filter: 'brightness(0) invert(1)', width: '30px', marginLeft: '10px'}}
        />
      </div>

      <ClassicModal open={rulesModal} close={setRulesModal}>
        <h1>Reglas e información</h1>
        Esta compra rápida es la esencia en la cual ganar una mayor cantidad de kilogramo para reducir el precio. Se permite compartir un enlace para amigos. El principio se basa en la compra al por mayor, donde el paso es de 5 kg. Cuando confirmamos el pedido, esperamos la venta final, y también le notificamos sobre los precios finales para los cuales se ordenará la mercancía.
        <br/><br/>
        Buena suerte en la venta!!!
      </ClassicModal>
    </div>
  )
}