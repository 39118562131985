import React from 'react'
import ModalUI from './../../ModalUI/ModalUI';
import TemplateItemEdit from './TemplateItemEdit/TemplateItemEdit';

const TemplEditModal = ({ 
  setEditModal, 
  editedItems, 
  setEditedItems, 
  setSearchModal, 
  setSendOrderModal}) => {

  console.log('items'+editedItems);

  

  return (
    <ModalUI setIsOpenModal={setEditModal} sx={{overflow: 'hidden'}}>
      <div className='OrderSwiper__itemsList__editItems'>
        {editedItems?.map((el, index) => 
          <TemplateItemEdit
            item={el} 
            key={el._id}
            setEditedItems={setEditedItems} 
            editedItems={editedItems}   
          />
        )}  
      </div>
      <hr style={{margin: '0 auto', color: '#252525', width: '90%'}}/>
      <button
        style={{
          width: '200px',
          height: 'fit-content',
          borderRadius: '10px',
          padding: '10px',
          backgroundColor: '#5FC56E',
          color: 'white',
          border: 'none',
          marginTop: '20px',
          outline: 'none',
          cursor: 'pointer',
          letterSpacing: '1px',
          fontWeight: 'bold',
          transition: '0.3s',

          '&:hover': {
            backgroundColor: '#5FC5AE',
          },
        }}
        onClick={() => {
          setSendOrderModal(true)
        }}
      >
        Clone Order
      </button>
      <button
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '10px',
          padding: '10px',
          backgroundColor: '#5FC56E',
          color: 'white',
          border: 'none',
          marginTop: '20px',
          outline: 'none',
          cursor: 'pointer',
          letterSpacing: '1px',
          fontWeight: 'bold',
          transition: '0.3s',
          fontSize: '32px',
          textAlign: 'center',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '0px',
          right: '15px',

          '&:hover': {
            backgroundColor: '#5FC5AE',
          },
        }}
        onClick={() => {
          setSearchModal(true)
        }}
      >
        +
      </button>
    </ModalUI>
  )
}

export default TemplEditModal