import React from 'react'
import ItemCounterModal from '../../../ModalWindows/ItemCardModal/ItemCounterModal/ItemCounterModal'
import { useDispatch, useSelector } from 'react-redux';
import useFindInCart from '../../../../../hooks/useFindInCart';
import useFindItemByUnit from '../../../../../hooks/useFindItemByUnit';
import unitEqual from '../../../../../constants/unitEqual';

const MapElemLine = ({item, list}) => {
  const grammMode = useSelector((state) => state.contextUI.grammMode)
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)
  const inCart = useFindInCart(item)

  return (
    <div style={{margin: "10px 0px"}}>
      <div className='contentCardPro' key={item?.title+item?.sku} style={{textAlign: 'start'}}>
        {item?.name}
        <div 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px', width: 'fit' }}
        >
          <ItemCounterModal item={item} key={item?.sku+item?.name} grammMode={grammMode}/>
          {item?.spoPrice ? <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
          <span style={{textDecoration: 'line-through'}}>
            {ivaSwitch ? (item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' : 
            ((item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(100+parseInt(item?.IVACP))*100).toFixed(2) +'€'
            }
          </span>
          <span style={{color: '#5FC56E'}}>
            {ivaSwitch ? (item?.spoPrice* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' : 
            ((item?.spoPrice* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(100+parseInt(item?.IVACP))*100).toFixed(2) +'€'
            }
          </span> </div> :
          <span>
            {ivaSwitch ? (item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' : 
            ((item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(100+parseInt(item?.IVACP))*100).toFixed(2) +'€'
            }
          </span>
          }
        </div>
      </div>
      <div 
        className='cartModal__body__item__row'
        style={{marginTop: '-10px', justifyContent: 'space-between', gap: '10px', width: ''}}
      >
        {item?.pdt &&
          <div className='contentCard__body__subtitle__info dias'>{item?.pdt}</div>}
        <div className='LineCard__aprox'>
        {unitEqual.find((el) => el.sku === item?.sku)?.und && ('und: '+unitEqual.find((el) => el.sku === item?.sku)?.und+" kg aprox ")
        }
        {unitEqual.find((el) => el.sku === item?.sku)?.caja && ('caja: '+unitEqual.find((el) => el.sku === item?.sku)?.caja+" kg aprox ")
        }
        {unitEqual.find((el) => el.sku === item?.sku)?.saco && ('saco: '+unitEqual.find((el) => el.sku === item?.sku)?.saco+" kg aprox ")
        }
        </div>
      </div>
    </div>
  )
}

export default MapElemLine