import React, { useState, useEffect, useCallback, useRef } from 'react'
import './SearchBar.scss'
import { mainApi } from '../../../../store/services/mainApi'
import searchIcon from '../../assets/images/Search_active.png'
import { setSearch } from '../../../../store/slices/search'
import { CircularProgress } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setItemModal } from '../../../../store/slices/contextUI'
import { Icon } from '@iconify/react/dist/iconify.js'
import { setDataSearch } from '../../../../store/slices/filterData'
import { setAllItemsCart, setCartElemQuantityValue } from '../../../../store/slices/allItemsCart'
import { allItemsCartSlice } from './../../../../store/slices/allItemsCart';
import useReplaceAccent from './../../../../hooks/useReplaceAccent';

const SearchBar = () => {
  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  });
  const inputRef = useRef(null);
  const tradeName = useParams().tradeName
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showItems, setShowItems] = useState(false);
  const [word, setWord] = useState('')
  const [itemId, setItemId] = useState('h')
  const handleGoToSearchPage = () => {
    if (tradeName) {navigate(`/searchPage/${tradeName}`)}
    else {navigate('/searchPage/')}
    dispatch(setSearch(word))
    setShowItems(false)
  }

  const handleKeyPress = useCallback((event) => {
    if (event.key?.toLowerCase() === '`') {
      setShowItems(!showItems);
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);

    return () => {
      document.removeEventListener('keyup', handleKeyPress);
    };
  }, [handleKeyPress]);


  const { data: itemData, isLoading: isLoadingItem, isFetching: isFetchingItem } = mainApi.useGetProductByIdQuery(itemId)
  useEffect(() => {
    if (itemData) {
      dispatch(setItemModal({item: itemData, isShow: true}))
    }
  }, [itemData])

  const { data: searchData, isFetching: isSearchFetching, isLoading: isLoadingSearch } = mainApi.useGetProductsAdminSearchInputQuery(word)


  const [elementHeight, setElementHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      const keyboardHeight = window.innerHeight - document.documentElement.clientHeight;
      setElementHeight(window.innerHeight - keyboardHeight-204);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [typingTimer, setTypingTimer] = useState(null);

  

  function replaceItem(item) {
    return {sku: item.sku, name: useReplaceAccent(item.name.toLowerCase())}
  }

  
  const searchDataMap = useSelector((store) => store.filterData.filterDataSearch)
  const [replaceNameData, setReplaceNameData] = useState([])

  useEffect(() => {
    if (!isLoading) {
      const mapData = allProductsData?.map((item) => replaceItem(item))
        setReplaceNameData(mapData)
    }
  }, [allProductsData, isLoading])

  useEffect(() => {
    if (!isLoading) {
      const replaceWord = useReplaceAccent(word.toLowerCase())
      const filterArray = replaceNameData?.filter((item) => item.name.toLowerCase().includes(replaceWord))
      dispatch(setDataSearch(allProductsData?.filter((el) => filterArray.map((item) => item.sku).includes(el.sku))?.filter((el) => el?.sku !== 'BHieCILman')));
    }
  }, [replaceNameData, word])

  const handleInputChange = (event) => {
    const { value } = event.target;
    clearTimeout(typingTimer);
    setTypingTimer(
      setTimeout(() => {
        setWord(value);
        dispatch(setSearch(value));
      }, 30)
    );
  };

  useEffect(() => {
    return () => {
      clearTimeout(typingTimer);
    };
  }, [typingTimer]);
  const handleAddToCart = async (item) => {
    dispatch(setAllItemsCart(item))
    dispatch(setCartElemQuantityValue({ value: 1, elem: item }))
  }

  const allItemsCart = useSelector((store) => store.allItemsCart.allItemsCart)


  return (
    <div 
      className='searchBar'
      style={{ filter: !isLoadingSearch ? 'none' : 'brightness(0.95)' }}
      onBlur={() => {setTimeout(() => setShowItems(false), 100)}}
    >
      <div 
        className='searchBar__input'
        onFocus={() => {setShowItems(true)}}
      >
        <input 
          ref={inputRef}
          type="text"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.defaultMuiPrevented = true;
              handleGoToSearchPage()
            }
          }}
          placeholder={tradeName !== undefined ? `Buscar todo` : ''}
          disabled={isLoadingSearch}
          onFocus={() => {setShowItems(true)}}
          onBlur={() => {setTimeout(() => setShowItems(false), 100)}}
          onChange={handleInputChange}
          value={word}
        />
        <div
          className='searchBar__input__showMore'  
          style={{display: word !== '' ? 'flex' : 'none', marginRight: '10px'}}
          onClick={() => setWord('')}
        >
          X
        </div>
        {word == '' ? <img src={searchIcon} alt="" /> :
        <div
          className='searchBar__input__showMore'  
          onClick={() => handleGoToSearchPage()}
        >
          <Icon icon="maki:arrow"/>
        </div>}
      </div>
      {isSearchFetching ? showItems && <CircularProgress style={{position: 'absolute'}}/> : showItems &&  
      <div className='searchBar__itemsContainer' key={'searchBar__itemsContainer'}>
          <div 
            className='searchBar__itemsContainer__droplist__go'
            onClick={() => handleGoToSearchPage()}  
          >
            Go
          </div>
        <div 
          className='searchBar__itemsContainer__droplist' 
          key={'searchBar__itemsContainer__droplist'}
          style={{ height: searchDataMap?.length < 8 ? 'fit-content' : `${elementHeight}px` }}
        >
          <div
            className='searchBar__itemsContainer__droplist__item'
          >
            Encontrados: {word.length < 1 ? 9932 : searchDataMap?.length}  
          </div>
          {word.length < 4 ?
          searchDataMap?.slice(0, 50).map((item) => 
            item.enabled === true &&
            <div 
              onClick={() =>  {
                setItemId(item._id)
                // setWord(item.name)
              }}
              key={item.id+'searchBar__itemsContainer__droplist__itemx'} 
              className='searchBar__itemsContainer__droplist__item'
            >
              <img src={item.img} alt="" />  
              <span>{item.name}</span>
              {!allItemsCart?.find((elem) => (elem.sku === item.sku)) ?
              <div
                className='searchBar__itemsContainer__droplist__item__addBtn'
                onClick={(e) => {
                  e.stopPropagation()
                  handleAddToCart(item)
                  setTimeout(() => inputRef.current.focus(), 101)
                }}
              >
                +
              </div>
              : <div>
                <svg width={30} fill="#5fc56d" viewBox="0 0 24 24" id="check-mark-circle-2" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" className="icon flat-line"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><polyline id="primary" points="21 5 12 14 8 10" style={{fill: 'none', stroke: '#5fc56d', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2'}}></polyline><path id="primary-2" data-name="primary" d="M20.94,11A8.26,8.26,0,0,1,21,12a9,9,0,1,1-9-9,8.83,8.83,0,0,1,4,1" style={{fill: 'none', stroke: '#5fc56d', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2'}}></path></g></svg>
              </div>}
            </div>) :
          searchDataMap?.map((item) => 
          item.enabled === true &&
          <div 
          onClick={() =>  {
            setItemId(item._id)
            // setWord(item.name)
            }}
            key={item.id+'searchBar__itemsContainer__droplist__iteme'} 
            className='searchBar__itemsContainer__droplist__item'>
            <img src={item.img} alt="" />  
            <span>{item.name}</span>
            {!allItemsCart?.find((elem) => (elem.sku === item.sku)) ?
              <div
                className='searchBar__itemsContainer__droplist__item__addBtn'
                onClick={(e) => {
                  e.stopPropagation()
                  handleAddToCart(item)
                  setTimeout(() => setShowItems(true), 201)
                }}
              >
                +
              </div>
              : <div>
                <svg width={30} fill="#5fc56d" viewBox="0 0 24 24" id="check-mark-circle-2" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" className="icon flat-line"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><polyline id="primary" points="21 5 12 14 8 10" style={{fill: 'none', stroke: '#5fc56d', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2'}}></polyline><path id="primary-2" data-name="primary" d="M20.94,11A8.26,8.26,0,0,1,21,12a9,9,0,1,1-9-9,8.83,8.83,0,0,1,4,1" style={{fill: 'none', stroke: '#5fc56d', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '2'}}></path></g></svg>
              </div>}
          </div>)
          }
        </div>
        {location.pathname !== '/searchPage' && 
        <div 
          className='searchBar__showMore'
          onClick={() => handleGoToSearchPage()}
          >Show more...</div>}
      </div>}
    </div>
  )
}

export default SearchBar