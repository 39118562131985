import axios from 'axios'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'

const TradeNameSynh = () => {
  const tradeNameParams = useParams().tradeName  
  

  useEffect(() => {
    if (tradeNameParams !== undefined) {
      const tradeId = async () => { 
        const responseLogin = await axios.get(`https://tpomobi.shop/getBuisnessContactByTradeName?tradeName=${tradeNameParams}`)
        const localStorageData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
        console.log(localStorageData);
        console.log(localStorage.getItem('userData'))
        localStorage.setItem('userData', JSON.stringify({
          ...localStorageData,
          tradeNameCode: responseLogin.data.tradeName,
          tradeNameAdress: responseLogin.data.name,
        }))
      }
      tradeId()
    }
  }, [tradeNameParams])
}

export default TradeNameSynh