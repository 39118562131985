import React, { useEffect, useState } from 'react'
import './App.css'
import { RouterProvider } from 'react-router-dom'
import { routerDeleverItems } from './router'
import 'normalize.css'
import AutoLogin from './pages/components/ModalWindows/LoginModal/AutoLogin/AutoLogin'
import CartElemInfoModal from './pages/components/ModalWindows/CartElemInfoModal/CartElemInfoModal'
import Footer from "./pages/components/Footer/Footer";
import { useSelector } from 'react-redux'
import FavoriteModal from './pages/components/ModalWindows/FavoriteModal/FavoriteModal'
import AutoDataSynhronaizer from './pages/components/AutoDataSynhronaizer/AutoDataSynhronaizer'
import ItemCardModal from './pages/components/ModalWindows/ItemCardModal/ItemCardModal'
import WarningModal from './pages/components/ModalWindows/WarningModal/WarningModal'
import AlertModal from './pages/components/ModalWindows/AlertModal/AlertModal'
import ErrorHandler from './pages/ErrorPage/ErrorHandler'


const App = () => {
  const openFavoriteModal = useSelector(state => state.contextUI.uiModal.favoriteModal)
  const ItemModalData = useSelector(state => state.contextUI.uiModal.itemModal)
  // const [isAlertModal, setIsAlertModal] = useState(true)

  return (
    <div className='App'>
      <ErrorHandler>
        <div className='App__padding'>
          <RouterProvider router={routerDeleverItems} />
          
          {openFavoriteModal && <FavoriteModal/>}
          <CartElemInfoModal/>
          
        
          
          {/* {isAlertModal && 
          <AlertModal closeFunc={setIsAlertModal}>
            Hola buenas tardes!
            <br/>
            <br/>
            Martes 24/09, es festivo, *no vamos a tener servicio* 
            <br/>
            <br/>
            Pero el miércoles, solo está semana, vamos a entregar la mercancía 
            <br/>
            <br/>
            Por favor, hagan sus comandas para mañana completas, para llegar hasta el martes incluido.
            <br/>
            <br/>
            Gracias
          </AlertModal>} */}
          <AutoDataSynhronaizer/>
          {ItemModalData.isShow ? <ItemCardModal card={ItemModalData.item}/> : <></>}
        </div>
      </ErrorHandler>
        <Footer/>
    </div>
  )
}

export default App