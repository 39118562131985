import React, { useEffect, useState } from 'react'
import ModalUI from '../../ModalUI/ModalUI';
// import Swiper from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router';
import { mainApi } from '../../../../store/services/mainApi';
import TemplateItem from './TemplateItem/TemplateItem';

const FTOOrderModal = ({setIsOpenModal, setCloneOrder, setEditModal, setSendOrderModal}) => {
  const tradeNameParams = useParams().tradeName

  const [realIndex, setRealIndex] = useState(0); 

  const { data: allOrders, isLoading, refetch } = mainApi.useGetOrdersByTradeNameQuery(tradeNameParams)

  

  return (
    <ModalUI setIsOpenModal={() => setIsOpenModal(false)}>
      {allOrders?.length > 0 && !isLoading&& 
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="OrderSwiper"
        slidesPerView={1}
        // autoHeight={true}
        onSlideChange={(swiper) => {
          setRealIndex(swiper.realIndex);
        }}
      >
        {allOrders?.slice(0, 15).map((el, index) => 
          <SwiperSlide key={el._id+'Swiper'} virtualIndex={el._id}>
            <div className='OrderSwiper__itemsList'>
              {el.items.map((item, index) =>
                <TemplateItem item={item} key={item.sku+index}/>
              )}
            </div>
          </SwiperSlide>
        )}
      </Swiper>}
      {allOrders?.length === 0 && !isLoading && <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', fontSize: '18px', fontWeight: '500', color: '#252525', marginTop: '10px'}}>No orders</div>}
      <hr style={{margin: '10px auto 0 auto', color: '#252525', width: '90%'}}/>
      {isLoading ? <CircularProgress/>: <div style={{ display: 'flex', gap: '10px', justifyContent: 'center'}}>
        <button
          style={{
            width: '150px',
            height: 'fit-content',
            borderRadius: '10px',
            padding: '10px',
            backgroundColor: '#5FC56E',
            color: 'white',
            border: 'none',
            marginTop: '20px',
            outline: 'none',
            cursor: 'pointer',
            letterSpacing: '1px',
            fontWeight: 'bold',
            transition: '0.3s',

            '&:hover': {
              backgroundColor: '#5FC5AE',
            },
          }}
          onClick={() => {
            setSendOrderModal(true)
            setCloneOrder(allOrders[realIndex])
            
            setIsOpenModal(false)
          }}
        >
          Copy Order
        </button>
        <button
          style={{
            width: '150px',
            height: 'fit-content',
            borderRadius: '10px',
            padding: '10px',
            backgroundColor: '#458f50',
            color: 'white',
            border: 'none',
            marginTop: '20px',
            outline: 'none',
            cursor: 'pointer',
            letterSpacing: '1px',
            fontWeight: 'bold',
            transition: '0.3s',

            '&:hover': {
              backgroundColor: '#5FC5AE',
            },
          }}
          onClick={() => {
            setCloneOrder(allOrders[realIndex])
            setEditModal(true)
            setIsOpenModal(false)
          }}
        >
          Clone Order
        </button>
      </div>}
    </ModalUI>
  )
}

export default FTOOrderModal