import { debounce } from 'lodash';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { mainApi } from '../../../../store/services/mainApi';
import { setAllItemsCart, setItemsCart } from '../../../../store/slices/allItemsCart';
import orderDataConst from '../../../../constants/OrderData';

const CartSynch = () => {
  const allItemsCart = useSelector((state) => state.allItemsCart.allItemsCart)
  const userData = useSelector((state) => state.accountData.userAcc)
  const IsCartSynhronaizer = useSelector((state) => state.contextUI.synhronaizers.cartSynh)
  const userPhone = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('LoginData'))?.phone : ''
  // console.log(userPhone);
  const [updateCartContact, { data: resultCart, isSuccess: isSuccessCart }] = mainApi.useUpdateCurrentCartContactMutation()
  const dispatch = useDispatch()


  const updateCart = async () => {
    const cart = allItemsCart
    // console.log(cart);
    // console.log('allItemsCart-reload')
    if (userPhone) {
      await updateCartContact({ data: {
        phone: userPhone.replace('+', ''),
        currentCart: allItemsCart,
      }})
    }
  }
  useEffect(() => {
    if (userPhone && userData.currentCart) {
      localStorage.removeItem('allItemsCart')
      localStorage.setItem('allItemsCart', JSON.stringify(userData.currentCart))
      dispatch(setItemsCart(userData.currentCart))
    }
  }, [userData])
  useEffect(() => {
    if (userPhone) {
      if (IsCartSynhronaizer) {const debouncedUpdateCart = debounce(updateCart, 1000)
      localStorage.removeItem('allItemsCart')
      localStorage.setItem('allItemsCart', JSON.stringify(allItemsCart))
      debouncedUpdateCart()
      return () => {
        debouncedUpdateCart.cancel()
      }}
    } else {
      localStorage.removeItem('allItemsCart')
      localStorage.setItem('allItemsCart', JSON.stringify(allItemsCart))
    }
  }, [allItemsCart, userPhone])
}

export default CartSynch