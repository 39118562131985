import React, { useEffect, useState } from 'react'
import './TemplateItemEdit.scss'
import CloseElem from '../../../assets/images/closeElem.svg'

const TemplateItemEdit = ({item, setEditedItems, editedItems, keyId}) => {
  const foundItem = editedItems.find((el) => el.sku === item.sku);
  console.log(editedItems);
  const [dataItem, setDataItem] = useState({quantity: foundItem.quantity, marketPriceCP: foundItem.marketPriceCP});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const sku = event.target.dataset.sku;
    const field = event.target.dataset.field;

    if (sku && field) {
      const item = editedItems.find((item) => item.sku === sku);
      if (item) {
        const newItem = { ...item, [field]: value === '' ? 0 : parseFloat(value) };
        setDataItem({ ...dataItem, [field]: value });
        const filterItems = editedItems?.filter((item) => item.sku !== sku);
        const array = filterItems.concat(newItem).sort((a, b) => a.name.localeCompare(b.name));
        setEditedItems(array);
      }
    }
  };
  

  return (
    <div className='TemplateItemEdit'>
      <div className='TemplateItemEdit__data'>
        <div className="TemplateItemEdit__data__img">
          <img src={item.img} alt="" />
        </div>
        <div className="TemplateItemEdit__data__title">
          {item.name}
        </div>
        <div>
          <img 
            src={CloseElem}
            onClick={() => setEditedItems(editedItems => editedItems?.filter(el => el.sku !== item.sku))}
          />
        </div>
      </div>

      <div className='TemplateItemEdit__dataParams'>
          <div className='TemplateItemEdit__dataParams__params'>
            Quantity: 
            <input 
              key={item.sku}
              type="number"
              name={item.sku} 
              data-sku={item.sku}
              data-field="quantity"
              value={dataItem.quantity}
              onChange={handleInputChange}
            />
          </div>
          <div className='TemplateItemEdit__dataParams__params'>
            Price: 
            <input 
              key={item.sku}
              type="number" 
              name={item.sku} 
              data-sku={item.sku}
              data-field="marketPriceCP"
              value={(dataItem.marketPriceCP*dataItem.quantity).toFixed(2)}
              disabled={true}
              style={{ border: '1px solid rgb(65 144 76)', background: '#e8e8e8'}}
            />
          </div>
      </div>
    </div>
  )
}

export default TemplateItemEdit