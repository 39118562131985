import React, { useMemo } from 'react'
import { mainApi } from '../store/services/mainApi'


const updateQuantity = (list, allProductsData) => {
  const listSKUs = list?.map((el) => el.sku);

  const updatedList = allProductsData?.filter((el) => listSKUs?.includes(el.sku))?.map((el) => {
    if (listSKUs?.includes(el.sku)) {
      const oldObj = list?.find(obj => obj.sku === el.sku);
      if (oldObj) {
        return {
          ...el,
          quantity: oldObj.quantity,
          quantityOrder: oldObj.quantityOrder,
          currentUnit: oldObj.currentUnit,
        }
      }
    }
    return el;
  });

  return updatedList;
};

const useUpdatePrice = (list) => {
  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  });

  if (!allProductsData || isLoading) {
    return []; // или возвращайте какой-то дефолтный элемент
  }

  const updatedList = updateQuantity(list, allProductsData);

  if (!updatedList || updatedList.length === 0) {
    return []; // или возвращайте какой-то дефолтный элемент
  }

  return updatedList?.sort((a, b) => a?.name?.localeCompare(b.name));
};

export default useUpdatePrice