import { useSelector } from 'react-redux';
import useUpdateCart from './useUpdateCart';

const useSplitCart = () => {
  const allItemsCart = useSelector((state) => state.allItemsCart.allItemsCart);
  // const allItemsUpdatedCart = useUpdateCart()
    const allCart = [...allItemsCart].sort((a, b) => a.name.localeCompare(b.name));
    const groupedData = allCart.reduce((acc, item) => {
      const groupKey = item.dias ? `dias${item.dias.toString()}` : item.stock == '1' ? 'stock' :  item.stock == '0' ? 'noStock' : 'tbc';
      
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      
      acc[groupKey].push(item);
      
      return acc;
    }, {});
    // console.log(groupedData);
    return groupedData;
}

export default useSplitCart