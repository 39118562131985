import React, { useEffect } from 'react'
import './PreviewMenu.scss'
import ProductsIcon from '../../../../assets/images/FreskoIcon.svg'
import TypesIcon from '../../../../assets/images/AppleIcon.svg'
import SamplesIcon from '../../../../assets/images/SamplesIcon.svg'
import CarNav from '../../../../assets/images/Car-Nav.png'
import HeartNav from '../../../../assets/images/Heart-Nav.png'
import CalendarNav from '../../../../assets/images/Calendar-Nav.png'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setCartModal } from '../../../../../../store/slices/contextUI'
import PreviewCart from '../PreviewCart/PreviewCart'
import CartModal from '../../../../ModalWindows/CartModal/CartModal'
import { useNavigate, useParams } from 'react-router'
import { Box, Button, Modal, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import TelegIcon from '../../../../assets/images/logos_telegram.svg'
import WhatsUp from '../../../../assets/images/WhatsupIconLogin.svg'
import useSendLog from '../../../../../../hooks/useSendLog'

const PreviewMenu = () => {
    const showCart = useSelector(state => state.contextUI.uiModal.cartModal)
    const [showMenu, setShowMenu] = useState(false)
    const allItemCart = useSelector(state => state.allItemsCart.allItemsCart)
    const dispatch = useDispatch()
    const tradeNameParams = useParams().tradeName
    const platformParam = useParams().platform
    const navigate = useNavigate()
    const { register, handleSubmit, reset, control, setValue } = useForm();
    const userAcc = useSelector(state => state.accountData.userAcc)
    const localStorageData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const [tradeName, setTradeName] = useState(localStorage.getItem('userData') ? localStorageData.tradeNameCode : userAcc.tradeName !== '' ? userAcc.tradeName : '')

    const handleTardeNameConfirm = () => {
        localStorage.setItem('userData', JSON.stringify({
            ...localStorageData,
            tradeNameCode: tradeName,
        }))
        navigate(`/category/${tradeName}`)
        setShowMenu(false)
    }
    const handleClickCartPersonal = useSendLog('Click on cart in personal links', {});
    const handleClickCart = useSendLog('Click on cart', {});


	return (
    <>  
        {showCart && 
        <CartModal/>
        // <PreviewCart/>
        }
        <div className='previewNav__menu'>
            {allItemCart.length > 0 && <div className='previewNav__menu__count'>{allItemCart.length}</div>}
            <img 
                src={CarNav} 
                onClick={() => {
                    if (tradeNameParams !== undefined) {
                        if (platformParam !== undefined) {
                            navigate(`/newOrder/${platformParam}/${tradeNameParams}`)
                            handleClickCartPersonal()
                        } else {
                            navigate(`/newOrder/${tradeNameParams}`)} window.scrollTo(0, 0)
                            handleClickCartPersonal()
                    } else {
                        dispatch(setCartModal(!showCart))
                        handleClickCart()
                    }
                    
                }
                }/>
            <img src={HeartNav} onClick={() => tradeName ? navigate(`/category/${tradeName}`) : setShowMenu(!showMenu)}/>
            {/* <img src={CalendarNav}/> */}
        </div>
        {showMenu && 
        <Modal open={showMenu} onClose={() => setShowMenu(!showMenu)}>
            <Box className='NewOrderPage__mainEditOrder__sendOrder__modal'
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                display: 'flex',
                flexDirection: 'column',
                outline: 'none',
                border: '3px solid #5FC56E',
                fontFamily: 'Montserrat',
                fontSize: '17px',
                color: '#5FC56E',
                borderRadius: 6,
                gap: 2,
                p: 4,
            }}>
                <span>Para recibir tu lista de productos favoritos, entroduzca el código de tu local:</span>
                <Controller 
                    name={tradeName}
                    control={control}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                        formState,
                    }) => (
                        <TextField
                            helperText={error ? error.message : null}
                            size='small'
                            error={!!error}
                            onChange={(e) => setTradeName(e.target.value)}
                            value={tradeName}
                            fullWidth
                            variant='outlined'
                        />
                    )}
                />
                <Button 
                    variant='contained' 
                    onClick={() => handleTardeNameConfirm()}
                    color="success"
                    disabled={tradeName === ''}
                >
                    Continuar
                </Button>
                <span>No lo sabes? <br />
Pídelo porfavor aquí</span>
                <div style={{display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={TelegIcon} width={50} alt="Telegram" onClick={() => {window.open('https://t.me/tpobcn', '_blank')}}/>
                    <img src={WhatsUp} width={60} alt="Whatsapp" onClick={() => {window.open('https://wa.me/34642868541', '_blank')}}/>
                </div>
            </Box>
        </Modal>}
    </>
  )
}

export default PreviewMenu